<template>
  <div class="reservation">
    <div class="reservation-box">
        <div class="title">预约会议</div>
        <div class="reservation-content">
            <div class="input-box">
                <van-field v-model="meetingRoomName" label="会议室名称" placeholder="请输入用户名" readonly input-align="right"/>
            </div>
            <div class="input-box">
                <van-field v-model="form.conferenceTheme" label="会议主题" placeholder="请输入" input-align="right"/>
            </div>
            <div class="input-box">
                <van-field readonly v-model="form.conferenceDate" label="会议日期" placeholder="请输入" input-align="right"/>
                <!-- <van-field readonly clickable name="calendar" :value="form.conferenceDate" label="会议日期" placeholder="点击选择日期" @click="showCalendar = true"  right-icon="arrow" input-align="right" /> -->
            </div>
            <!-- <div class="input-box">
                <van-field readonly clickable name="datetimePicker" :value="datatime" label="会议时段" placeholder="点击选择时间" @click="showPicker = true" right-icon="arrow" input-align="right" /> 
            </div> -->

            <div class="textare-box">
                <div class="reser-title">会议时段</div>
                <div class="reser-qj">
                    <div class="qdtime"><van-field readonly clickable v-model="form.conferenceStartTime" placeholder="请选择开始时间" @click="openPicker(1)" input-align="center" /> </div>
                    <div class="qdstyle">~</div>
                    <div class="qdtime"><van-field readonly clickable v-model="form.conferenceEndTime" placeholder="请选择结束时间" @click="openPicker(2)" input-align="center" /> </div>
                </div>
            </div>
            <div class="textare-box">
                <!-- <van-field v-model="value" readonly clickable label="承办部门" @click="showTree = true" right-icon="arrow" placeholder="请选择" input-align="right"/> -->

                <div class="reser-title">承办部门</div>
                <div class="reser-qj" style="padding-top:10px">
                    <treeselect placeholder="请选择" v-model="form.undertakeDepart" :multiple="false" 
                    :normalizer="normalizer"
                    :options="options" :flat="true" :sort-value-by="sortValueBy" :default-expand-level="1"/>
                </div>
            </div>
            <!-- <div class="textare-box">
                <div class="reser-title">承办部门</div>
                <div class="">
                    <treeselect placeholder="请选择" v-model="value1" :multiple="false" :options="options" :flat="true" :sort-value-by="sortValueBy" :default-expand-level="1"/>
                </div>
            </div> -->
            
            <div class="input-box">
                <van-field v-model="isMeetingAffairs" readonly clickable label="是否需要会务" @click="openSheet(1)" right-icon="arrow" placeholder="请选择" input-align="right"/>
            </div>
            <div class="input-box">
                <van-field v-model="isSeatPlate" readonly clickable label="是否需要座牌" @click="openSheet(2)" right-icon="arrow" placeholder="请选择" input-align="right"/>
            </div>
            
            <div class="textare-box">
                <div class="reser-title">座牌信息</div>
                <div class="textare-con">
                    <van-field v-model="form.seatPlateInfo" type="textarea" :autosize= '{ maxHeight: 120, minHeight: 120 }' placeholder="请输入座牌信息"/>
                </div>
            </div>
            <div class="textare-box">
                <div class="reser-title">所需物料</div>
                <div class="textare-con">
                    <van-field v-model="form.material" type="textarea" :autosize= '{ maxHeight: 120, minHeight: 120 }' placeholder="请输入物料信息"/>
                </div>
            </div>

            <div class="textare-box">
                <div class="reser-title border-bottom" @click="showList=true">参会人员 <span>请选择<van-icon name="arrow" /></span></div>
                <!-- <div>
                    <van-field v-model="value" readonly clickable label="参会人员" @click="showList=true" right-icon="arrow" placeholder="请选择" input-align="right"/>
                </div> -->

                <div class="userInfo" v-if='usersPeo.length > 0'>
                    <div class="userwrapper" v-for="(item,index) in usersPeo" :key='index'>{{item.realname}} <span>-</span>{{item.phone}} <span class="close" @click="deleteItemPeo(item,index)">x</span></div>
                </div>
            </div>

            <div class="textare-box">
                <div class="reser-title border-bottom">其他参会人员</div>
                <div class="userInfo" v-if='list.length > 0'>
                    <div class="userwrapper" v-for="(item,index) in list" :key='index'>{{item.userName}} <span>-</span>{{item.userPhone}} <span class="close" @click="deleteItem(index)">x</span></div>
                </div>
                <div class="reser-input">
                    <input type="text" v-model="username" class="name" placeholder="请输入姓名"> - <input type="text" v-model="phone" maxlength='11' class="phone" placeholder="请输入手机号码">
                </div>
                <div class="addbtn" @click="addList">新增</div>                
            </div>

            
        </div>
        
        <div class="subbtn" @click="subFn">立即提交</div>
    </div>

    <!-- 日期 -->
    <!-- <van-calendar v-model="showCalendar" @confirm="onConfirm" color="#1989fa" /> -->

    <!-- 时间段 -->
    <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar @confirm="onPickerConfirm" :columns="columns" />
    </van-popup>
    
    <!-- <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar @confirm="onPickerConfirm" :columns="columnsEnd" />
    </van-popup> -->
    
    <!-- 选择部门 -->
     <!-- <van-popup v-model="showTree" position="bottom" :style="{ height:'50%'}">
        <treeselect placeholder="请选择" v-model="value1" :multiple="false" 
        :normalizer="normalizer"
        :options="options" :flat="true" :sort-value-by="sortValueBy" :default-expand-level="1"/>
    </van-popup> -->

    <!-- 选择人-->
     <van-popup v-model="showList" position="bottom" :style="{ height:'100%'}">
        <!-- <treeselect placeholder="请选择" v-model="value1" :multiple="false" :options="options" :flat="true" :sort-value-by="sortValueBy" :default-expand-level="1"/> -->
        <div class="closesTyle" @click="closeDep">关闭</div>
        <div style="padding:20px"><!--  :current="curNameId" -->
            <TreeFolder :tree-data="options" @tree-node-click="nodeClick" />
        </div>
    </van-popup>

    <!-- 选择人员 -->
    <van-popup v-model="showTree" position="bottom" :style="{ height:'100%'}">
        <div class="topsTyle">
            <div class="" @click="closePeo">关闭</div>
            <div class="surestyle" @click="sureFn">确定</div>
        </div>
        <div style="padding:10px 20px 20px">
            <div class="depname">{{departName}}：</div>
            <van-checkbox-group v-model="result">
                <div style="padding:10px" v-for='item in peopleList' :key="item.id">
                    <van-checkbox shape="square" :name="item.id">{{item.realname}} <span></span> {{item.phone}}</van-checkbox>
                </div>
                </van-checkbox-group>
        </div>
    </van-popup>


    
    <!-- 选择是否 -->
    <!-- <van-popup v-model="showTree" position="bottom">
        <treeselect placeholder="请选择" v-model="value1" :multiple="false" :options="options" :flat="true" :sort-value-by="sortValueBy" :default-expand-level="1"/>
    </van-popup> -->

    <van-action-sheet v-model="showSheet" :actions="actions" @select="onSelect"   cancel-text="取消" close-on-click-action @cancel="onCancel"/>
            <!-- <treeselect v-model="value1" :multiple="true" :options="options" /> -->
  </div>
</template>

<script>
import { Field, Toast, Picker, Cell, CellGroup, Calendar ,DatetimePicker, Popup , ActionSheet ,Checkbox, CheckboxGroup,Icon  } from "vant";
import Cookies from "js-cookie";
import config from "@/config";
import TreeFolder from "@/components/TreeFolder";
// import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "index",
  data() {
    return {
        meetingRoomName:this.$route.query.meetingRoomName,
        meetingRoomId:this.$route.query.meetingRoomId,
        // day:this.$route.query.day,
        beginTime:this.$route.query.bookableStartTime?this.$route.query.bookableStartTime.split(':')[0] : 0,
        overTime:this.$route.query.bookableEndTime?this.$route.query.bookableEndTime.split(':')[0] : 0,
        list:[],
        peopleList:[],
        allPeopleList:[],
        result:[],
        username:'',
        phone:'',

        form:{
            conferenceRoomId:this.$route.query.id,
            conferenceTheme:'',
            conferenceDate:this.$route.query.day,

            conferenceStartTime:"",
            conferenceEndTime:"",

            undertakeDepart:null,

            isMeetingAffairs:"",
            isSeatPlate:'',

            seatPlateInfo:'',
            material:'',

            otherList:'',
            users:''
        },
        
        // beginTime:0,
        // overTime:0,
        
        isMeetingAffairs:"",
        isSeatPlate:'',

        value:"",
        value6:"",
        value7:"",

        dataVal:'',
        datatimestart:"",
        datatimeend:'',
        setTime:'',

        setYn:'',

        showCalendar: false,
        showPicker: false,
        showTree:false,
        showSheet:false,
        showList:false,

        sortValueBy: 'ORDER_SELECTED',
        actions: [{ name: '是',value:"1" }, { name: '否',value:"0" }],
        // define the default value
        value1: null,
        // define options
        options: [ {
          id: 'a',
          label: 'a',
          children: [ {
            id: 'aa',
            label: 'aa',
          }, {
            id: 'ab',
            label: 'ab',
          } ],
        }, {
          id: 'b',
          label: 'b',
        }, {
          id: 'c',
          label: 'c',
        } ],

        normalizer(node) {
            // console.info(Object.prototype.toString.call(node.children))
            // 去掉children=[]的children属性
            if (node.children == 'null' || node.children == ''  || node.children == null) {
                delete node.children
            }
            return {
                id: node.id,
                label: node.departName,
                children: node.children,
            }
        },
        
        // 开始时间段
        columns: [
            // 第一列
            {
                values: ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                defaultIndex: 0,
            },
            // 第二列
            {
                values: ['00', '30'],
                defaultIndex: 0,
            },
        ],
        columnsBeginIndex:0,
        columnsBeginSecondIndex:0,

        columnsEndIndex:0,
        columnsEndSecondIndex:0,


        treeData:[
            { id: 1, name: '一级1' },
            {
                id: 2,
                name: '一级2',
                children: [
                { id: 3, name: '二级2-1' },
                { id: 4, name: '二级2-2' }
                ]
            },
            {
                id: 22,
                name: '一级2',
                children: [
                { id: 23, name: '二级2-1' },
                { id: 24, name: '二级2-2' }
                ]
            },
            {
                id: 15,
                name: '一级3',
                children: [
                {
                    id: 16,
                    name: '二级3-1',
                  
                },
                
                { id: 17, name: '三级3-1-1' },
                { id: 18, name: '三级3-1-2' },
                { id: 19, name: '二级3-2' },
                { id: 110, name: '二级3-3' }
                ]
            }
            ,
            {
                id: 5,
                name: '一级3',
                children: [
                {
                    id: 6,
                    name: '二级3-1',
                  
                },
                
                { id: 7, name: '三级3-1-1' },
                { id: 8, name: '三级3-1-2' },
                { id: 9, name: '二级3-2' },
                { id: 10, name: '二级3-3' }
                ]
            }
        ],
        curNameId:0,
        departName:'',
        users:[],
        usersPeo:[],
    };
  },
  methods: {
    closeDep(){
        this.showList = false
        // console.info(this.allPeopleList)
        this.usersPeo =[]
        this.result.forEach(item=>{
            this.allPeopleList.forEach(items => {
                if(item == items.id){
                    // this.users.push(items.id)
                    this.usersPeo.push(items)
                }
            })
        })
    },
    closePeo(){
        // this.result = []
        this.showTree = false
    },
    sureFn(){
        // console.info(this.result)
        this.showTree = false
    },
    // 提交
    async subFn(){
        this.form.otherList = JSON.stringify(this.list)
        
        this.users =[]

        this.usersPeo.forEach(item=>{this.users.push(item.id)})
        this.form.users = this.users.join(',')
        console.info(this.form)

        Toast.loading({
            duration: 0,
            forbidClick: true,
        });
        await this.$post(`/conference/reservation/addReservation`,this.form).then(
            (res) => {
                if (res.code == "0") {
                    Toast(res.message)
                    this.$router.go(-1)
                }else{
                    Toast(res.message)
                }
            }
        );
        
    },
    // 初始化时间段选择日期
    intervalFn(){
        let hours = [],defaultHours=[],hoursVal=[]
        defaultHours = [9,10,11,12,13,14,15,16,17,18,19]
        hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
        console.info(this.beginTime)
        if(this.beginTime > 0){
            var arr1 = hours.filter((item)=>this.beginTime <item && item <this.overTime)
            this.columns[0].values = arr1
        }else{
             this.columns[0].values = defaultHours
        }
        console.info(hoursVal)
    },  
    // 获取机构部门
    async queryTreeList() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      await this.$get(`/sys/sysDepart/queryTreeList`).then(
        (res) => {
          if (res.code == "0") {
            Toast.clear()
            this.options = res.result;
          }
        }
      );
    },
    // 获取人员
    async queryUserComponentData(val) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
        let params = {
            pageNo: 1,
            pageSize: 10000,
            departId: val,
      };
      await this.$get(`/sys/user/queryUserComponentData`,params).then(
        (res) => {
          if (res.code == "200") {
            Toast.clear()
            if(val){
                this.peopleList = res.result.records;
            }else{
                this.allPeopleList = res.result.records;
            }
          }
        }
      );
    },
    
    // onConfirm(time) {
    //   this.value = time;
    //   this.showPicker = false;
    // },
    // 会议日期
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
        this.form.conferenceDate = this.formatDate(date);
        this.showCalendar = false;
    },
    // 时间段 
    openPicker(val){
        this.setTime = val
        if(val == 1){
            this.columns[0].defaultIndex = this.columnsBeginIndex
            this.columns[1].defaultIndex = this.columnsBeginSecondIndex
        }else{
            this.columns[0].defaultIndex = this.columnsEndIndex
            this.columns[1].defaultIndex = this.columnsEndSecondIndex
        }
        this.showPicker = true
    },
    onPickerConfirm(value, index) {
        console.info(value)
        console.info(index)
        if(this.setTime == 1){
            this.form.conferenceStartTime = value.join(":")
            this.columnsBeginIndex = index[0]
            this.columnsBeginSecondIndex = index[1]
        }else{
            
            this.form.conferenceEndTime= value.join(":")
            this.columnsEndIndex = index[0]
            this.columnsEndSecondIndex = index[1]
        }
        this.showPicker = false
    },
    // 是否
    openSheet(val){
        this.setYn = val
        this.showSheet=true
    },

    onSelect(item) {
        console.info(item)
        // 默认情况下点击选项时不会自动收起
        // 可以通过 close-on-click-action 属性开启自动收起
        //   this.show = false;
        if(this.setYn == 1){
            this.isMeetingAffairs = item.name
            this.form.isMeetingAffairs = item.value
        }else{
            
            this.isSeatPlate = item.name
            this.form.isSeatPlate = item.value
        }
        // console.info(item);
    },
    onCancel() {
      Toast('取消');
    },
    nodeClick(val) {
        this.departName = val.departName
        this.queryUserComponentData(val.id)

        this.showTree = true
        console.info(this.result)
        // this.curNameId = val.id
        // this.$set(val, 'curNameId', this.curNameId)
    },

    // 新增其它参会人员
    addList(){
      if(!this.username){
        Toast('请输入姓名')
        return false;
      }else if(!/^1[3456789]{1}\d{9}$/gi.test(this.phone)){
        Toast('请检查手机号')
        return false;
      }
      var obj = {
        userName:"",
        userPhone:"",
      }
      obj.userName = this.username
      obj.userPhone = this.phone
      this.list.push(obj)
      this.username = ''
      this.phone = ''
      console.info(this.list)
    },
    // 删除其它参会人员
    deleteItem(i){
      this.list.splice(i,1)
      console.info(this.list)
    },
    deleteItemPeo(item,i){
        this.usersPeo.splice(i,1)

        this.result = this.result.filter(function (items,index,arr4) {
            return items != item.id
        })

        
        console.info(this.result)

    }

  },
  created() {
    this.intervalFn()
    this.queryTreeList();
    this.queryUserComponentData()
  },
  mounted() {},
  components: {
    [Toast.name]: Toast,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Calendar.name]: Calendar,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
    [ActionSheet.name]: ActionSheet,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Icon.name]: Icon,
    Treeselect,
    TreeFolder
  },
};
</script>

<style lang="scss" scoped>
.closesTyle{
    text-align: right;
    padding: 10px 10px 0;
}
.topsTyle{
    display: flex;
    justify-content:space-between;
    padding: 10px 10px 0;
    .surestyle{
        color: #1890ff;
    }
}
.depname{
    padding: 10px 0;
}
::v-deep .vue-treeselect__control{
    border-color: transparent!important;
}
.sureBtn{
    height: 44px;
    line-height: 44px;
    text-align: right;
    span{
        color:#576b95;
        height: 100%;
        padding: 0 16px;
        font-size: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
}
.userInfo{
    font-size: 16px;
    padding: 15px 0 0px 15px;
    .userwrapper{
        padding: 5px 0;
        font-weight: 400;
        color: #333333;
        position: relative;
        span{
            color: #B0B0B0;
            margin: 0 10px;
            &.close{
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
                width: 20px;
                height: 20px;
                background: #E5E5E5;
                border-radius: 50%;
                text-align: center;
                line-height: 17px;
                cursor: pointer;

                font-size: 13px;
                font-weight: 400;
                color: #B3B3B3;
            }
        }
    }
}
.reservation {
  background: #f4f4f4;
  min-height: 100vh;
}
.reservation-box{
    overflow: hidden;
    .title{
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        background: linear-gradient(0deg, #526EFF 0%, #6384FF 100%);
        padding: 20px 0 188px;
        text-align: center;
    }
    .reservation-content{
        padding: 15px;
        margin: -150px 20px 20px;
        background: #FFFFFF;
        box-shadow: 0px 4px 29px 0px rgba(32,123,242,0.08);
        border-radius: 10px;
        position: relative;
        z-index: 2;
        &::after{
            content: '';
            width: 96%;
            border-top: 8px solid #CBE5FF;
            // height: 18px;
            // background: #CBE5FF;
            position: absolute;
            z-index: -1;
            left: 50%;
            top: -8px;
            // transform: translateX(-50%);
            margin-left: -48%;
            border-radius: 40px 40px 0 0;
        }
        .input-box{
            padding: 10px 0;
            border-bottom: 1px solid #F2F2F2;
            position: relative;
            &::after{
                content: '';
                width: 4px;
                height: 18px;
                background: #526EFF;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
           
        }
        .reser-title{
            padding: 15px 16px;
            font-size: 15px;
            position: relative;
            &::after{
                content: '';
                width: 4px;
                height: 18px;
                background: #526EFF;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &.border-bottom{
                border-bottom: 1px solid #F2F2F2;
            }
            span{
                float: right;
                color:#d3d3d3;
                .van-icon{
                    color: #969799;
                    padding-left: 7px;
                }
            }
        }
        .reser-qj{
            border-bottom: 1px solid #F2F2F2;
            padding-bottom: 10px;
            div{
                display: inline-block;
                vertical-align: middle;
                &.qdtime{
                    width: 45%;
                }
                &.qdstyle{
                    width: 10%;
                    text-align: center;
                }
            }
        }
        .textare-con{
            border: 1px solid #DEDEDE;
            // height: 120px;
            // textare
            
        }
        .reser-input{
            padding-top: 15px;
            input{
                padding: 12px 10px;
                border: 1px solid #D1D1D1;
                border-radius: 5px;
            }
            .name{
                width: 30%;
            }
            .phone{
                width: 45%;
            }
        }
        .addbtn{
            width: 120px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            background: #526EFF;
            border-radius: 5px;
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 15px;
        }

    }

    .subbtn{
        background: linear-gradient(14deg, #526EFF, #6384FF);
        border-radius: 25px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin: 0 20px 20px;
        padding: 15px 0;
    }
}
 ::v-deep .van-cell{
    font-size: 15px;
    font-weight: 500;
    color: #333333;
}

</style>