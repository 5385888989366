
<template>
    <div class="tree-item">
        <div v-for="item in treeData" :key="item.id">
            <div class="item-title" @click="itemNodeClick(item)">
                <span :class="{ 'fc-theme': item.id == curNameId }" v-text="item.departName"></span>
                <span v-if="item.children && item.children.length" class="left-class">
                    <van-icon name="arrow" style="transition: transform 0.3s" :class="{ 'negative-rotate': isOpen(item.id), 'fc-theme': item.id == curNameId }"/>  
                    <!-- // 根据id判断是否旋转图标和添加激活样式 -->
                </span>
                
            </div>
            <div v-if="item.children && item.children.length" v-show="isOpen(item.id)" class="item-childen">
                <tree-folder :treeData="item.children" :current="curNameId" @tree-node-click="$emit('tree-node-click', $event)"> </tree-folder>
            </div>
        </div>
    </div>
</template>
 
<script>
import { Icon ,Checkbox, CheckboxGroup ,RadioGroup, Radio } from 'vant';
export default {
    name: 'TreeFolder',
    props: {
        treeData: {
            type: Array,
            default: () => []
        },
        current: Number
    },
    data() {
        return {
            expandedKeys: [], // 当前列表需要展开的节点id组成的数组
            curNameId: 0, //保存current的值
            radio:''
        }
    },
    computed: {
		isOpen() {
			return function (id) {
				// 判断节点id在不在数组中，在则显示，不在则隐藏
				// return this.expandedKeys.includes(id)
				return true
			}
		}
	},
	watch: {
        //监听当前点击节点的id
		current: {
			handler(num) {
				this.curNameId = num
			},
			deep: true,
			immediate: true
		}
	},
    methods: {
        itemNodeClick(item) {
            // console.info(item)
            this.$emit('tree-node-click', item)
			if (item.children && item.children.length) {
				let index = this.expandedKeys.indexOf(item.id)
				if (index > -1) {
					// 如果当前节点id存在数组中，则删除
					this.expandedKeys.splice(index, 1)
				} else {
					// 如果当前节点id不存在数组中，则添加
					this.expandedKeys.push(item.id)
				}
			}

            // console.info(this.expandedKeys)
        }
    },
  components: { 
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
 
  },
}
</script>
 
<style scoped lang="scss">
    .left-class{
        float: right;
    }
    .tree-item{
        .item-title
            {
                padding: 8px;
                overflow: hidden;
                .left-style{float: left;
                &:first-child{margin-right: 20px;}}
    
            .name{ color: #000}
               
    
            .negative-rotate{
                transform: rotate(90deg)}
            }
    
        .item-childen{
            padding-left: 20px}
    }
</style>